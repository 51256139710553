<template>
    <Line
        ref="refLine"
        :text="refLineProps.text"
        :position="refLineProps.position"
        :layer="LAYER_FG"
        :bounds="textBounds"
        :debug="false"
    />
</template>

<script setup>
    import { computed, ref, watch, watchEffect } from 'vue';

    import { useViewportResize } from '@resn/gozer-vue';
    import { gsap } from '@resn/gsap';

    import { trimText } from '../../../../../../utils/text-utils';
    import { useBounds } from '~/components/gl/Bounds';
    import Line from '~/components/gl/common/Line.vue';
    import { useCutawayObject } from '~/composables/useCutawayObject';
    import { LAYER_FG } from '~/core/constants';

    const props = defineProps({
        text: { default: 'Commodo minim et excepteur' },
    });

    const visible = ref(false);

    const viewport = useViewportResize(() => resize(), true);

    const refLine = ref();
    const refLineProps = ref({ text: props.text, position: { x: 0, y: 0 } });

    const { object: objectCutaway } = useCutawayObject(null, { name: 'CutawayA' });

    const show = ({ delay = 0 } = {}) => {
        const { show: showLine } = refLine.value;

        const tl = gsap
            .timeline({
                delay,
                onStart: () => (visible.value = true),
                onComplete: () => (visible.value = false),
            })
            .add(showLine(), 0.1);

        return tl;
    };

    const setVisible = () => {
        objectCutaway.visible = visible.value;
    };

    useBounds(({ x, y }) => {
        objectCutaway.position.set(x, y, 0);
    });

    const resize = () => {
        const { height } = viewport;
        const heightLines = height * 0.2;
        refLineProps.value.position.y = heightLines / 2;
    };

    const textBounds = computed(() => {
        const { width, height } = viewport;
        const tH = height * 0.2;
        const tW = width;
        return { width: tW, height: tH };
    });

    const refreshText = (text) => {
        refLineProps.value = {
            text: trimText(text, 25),
            position: { x: 0, y: 0 },
        };
        resize();
    };
    watch(
        () => props.text,
        (val) => refreshText(val),
        { immediate: true }
    );

    watchEffect(setVisible);

    defineExpose({ show });
</script>
